<template>
    <b-card>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
                <b-row>
                    <b-col cols="6" md="6" lg="6">
                        <validation-provider #default="validationContext" name="Main Category">
                            <b-form-group label="Main Category" :state="getValidationState(validationContext)">
                                <v-select v-model="dataInfo.main_category_id" :options="mainCategoryOptions" :reduce="val => val.value" :clearable="true"/>
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col cols="6" md="6" lg="6">
                        <validation-provider #default="validationContext" name="Status" rules="required">
                            <b-form-group label="Status" :state="getValidationState(validationContext)">
                                <v-select v-model="dataInfo.status" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>

                <b-tabs pills align="right">
                    <b-tab v-for="language in dataInfo.languages" :key="language.language_id">
                        <template #title>
                            <span>{{ language.language_name }}</span>
                        </template>
                        <b-row class="mt-1">

                            <b-col cols="12" md="12" lg="12">
                                <validation-provider #default="validationContext" name="Title">
                                    <b-form-group :label="'Title (' + language.language_name + ')'">
                                        <b-form-input trim placeholder="Title" v-model="language.title" :state="getValidationState(validationContext)" @change="changeTitleValue(language.language_id)"/>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12" md="12" lg="12">
                                <validation-provider #default="validationContext" name="Description">
                                    <b-form-group :label="'Description (' + language.language_name + ')'">
                                        <b-form-textarea trim placeholder="Description" v-model="language.description" :state="getValidationState(validationContext)" rows="4" @change="changeDescriptionValue(language.language_id)"/>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12" md="6" lg="6">
                                <b-form-group :label="'Image (' + language.language_name + ')'">
                                    <b-media no-body>
                                        <b-media-aside>
                                            <b-img rounded :src="(language.imageBase64 ? language.imageBase64 : getApiFile(language.image_url, true))" height="80"/>
                                        </b-media-aside>

                                        <b-media-body class="mt-75 ml-75">
                                            <b-button variant="primary" size="sm" class="mb-75 mr-75" @click="selectFile('image_file_'+language.language_id)">Upload</b-button>
                                            <b-form-file
                                                    plain
                                                    :id="'image_file_'+language.language_id"
                                                    :name="'image_file_'+language.language_id"
                                                    :hidden="true"
                                                    accept=".jpg, .jpeg, .png, .gif"
                                                    @input="inputImageRenderer('image_file_'+language.language_id, language.language_id)"/>
                                            <b-button variant="outline-secondary" size="sm" class="mb-75 mr-75" @click="resetFile('image_file_'+language.language_id, language.language_id)">Reset</b-button>
                                            <b-card-text>Allowed JPG, GIF or PNG.</b-card-text>
                                        </b-media-body>
                                    </b-media>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="6" lg="6">
                                <b-form-group :label="'Mobile Image (' + language.language_name + ')'">
                                    <b-media no-body>
                                        <b-media-aside>
                                            <b-img rounded :src="(language.mobileImageBase64 ? language.mobileImageBase64 : getApiFile(language.mobile_image_url, true))" height="80"/>
                                        </b-media-aside>

                                        <b-media-body class="mt-75 ml-75">
                                            <b-button variant="primary" size="sm" class="mb-75 mr-75" @click="selectFile('mobile_image_file_'+language.language_id)">Upload</b-button>
                                            <b-form-file
                                                    plain
                                                    :id="'mobile_image_file_'+language.language_id"
                                                    :name="'mobile_image_file_'+language.language_id"
                                                    :hidden="true"
                                                    accept=".jpg, .jpeg, .png, .gif"
                                                    @input="inputImageRenderer('mobile_image_file_'+language.language_id, language.language_id)"/>
                                            <b-button variant="outline-secondary" size="sm" class="mb-75 mr-75" @click="resetFile('mobile_image_file_'+language.language_id, language.language_id)">Reset</b-button>
                                            <b-card-text>Allowed JPG, GIF or PNG.</b-card-text>
                                        </b-media-body>
                                    </b-media>
                                </b-form-group>
                            </b-col>


                            <b-col cols="12" md="12" lg="12">
                                <validation-provider #default="validationContext" name="h2">
                                    <b-form-group :label="'h2 (' + language.language_name + ')'">
                                        <b-form-input trim placeholder="h2" v-model="language.h2_title" :state="getValidationState(validationContext)"/>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12" md="12" lg="12">
                                <validation-provider #default="validationContext" name="h3">
                                    <b-form-group :label="'h3 (' + language.language_name + ')'">
                                        <b-form-input trim placeholder="h3" v-model="language.h3_title" :state="getValidationState(validationContext)"/>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12" md="12" lg="12">
                                <validation-provider #default="validationContext" name="Meta Title">
                                    <b-form-group :label="'Meta Title (' + language.language_name + ')'">
                                        <b-form-input trim placeholder="Meta Title" v-model="language.meta_title" :state="getValidationState(validationContext)"/>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12" md="12" lg="12">
                                <validation-provider #default="validationContext" name="Meta Description">
                                    <b-form-group :label="'Meta Description (' + language.language_name + ')'">
                                        <b-form-textarea trim placeholder="Meta Description" v-model="language.meta_description" :state="getValidationState(validationContext)" rows="4"/>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12" md="12" lg="12">
                                <validation-provider #default="validationContext" name="Meta Keywords">
                                    <b-form-group :label="'Meta Keywords (' + language.language_name + ')'">
                                        <b-form-input trim placeholder="Meta Keywords" v-model="language.meta_keywords" :state="getValidationState(validationContext)"/>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                        </b-row>
                    </b-tab>
                </b-tabs>

                <action-buttons :back-route="'cms-category-list'"/>
            </b-form>
        </validation-observer>
        <Overlay :busy="busy"></Overlay>
    </b-card>
</template>

<script>
import {
    BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BAvatar, BTabs, BTab, BFormTextarea, BMedia, BMediaBody, BFormFile, BMediaAside, BLink, BImg, BCardText,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/cms/category/store"
import languageModule from "@/views/cms/language/store"
import router from '@/router'
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import formValidation from '@core/comp-functions/forms/form-validation'
import {onUnmounted, ref} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, statusOptions} from "@core/utils/filter"
import {toastMessage} from "@core/utils/utils"
import {useInputIdImageRenderer} from "@core/comp-functions/forms/form-utils"

export default {
    components: {
        BCardText,
        BImg,
        BLink,
        BMediaAside,
        BFormFile,
        BMediaBody,
        BMedia,
        BFormTextarea,
        BTab,
        BTabs,
        BAvatar,
        BCard,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        BFormInvalidFeedback,

        vSelect,
        ActionButtons,
        Overlay,

        ValidationProvider,
        ValidationObserver,
    },
    props: {},
    data() {
        return {
            required,
            email,
        }
    },
    setup() {
        const STORE_MODULE_NAME = 'store'
        const STORE_LANGUAGE_MODULE_NAME = 'store-language'
        if (!store.hasModule(STORE_MODULE_NAME)) {
            store.registerModule(STORE_MODULE_NAME, storeModule)
            store.registerModule(STORE_LANGUAGE_MODULE_NAME, languageModule)
        }
        onUnmounted(() => {
            if (store.hasModule(STORE_MODULE_NAME)) {
                store.unregisterModule(STORE_MODULE_NAME)
                store.unregisterModule(STORE_LANGUAGE_MODULE_NAME)
            }
        })

        const toast = useToast()
        const busy = ref(false)

        const dataInfo = ref({
            id: 0,
            main_category_id: null,
            status: 1,
            languages: []
        })

        const selectFile = (input) => {
            document.getElementById(input).click()
        }
        const resetFile = (input, languageId) => {
            dataInfo.value.languages.filter(function (language) {
                if (language.language_id === languageId) {
                    if (input.includes('mobile')) {
                        language.mobile_image_url = null
                        language.mobileImageBase64 = null
                    } else {
                        language.image_url = null
                        language.imageBase64 = null
                    }
                }
            })
        }
        const inputImageRenderer = (input, languageId) => {
            useInputIdImageRenderer(input, base64 => {
                dataInfo.value.languages.filter(function (language) {
                    if (language.language_id === languageId) {
                        if (input.includes('mobile')) {
                            language.mobileImageBase64 = base64
                        } else {
                            language.imageBase64 = base64
                        }
                    }
                })
            })
        }
        const onSubmit = () => {
            busy.value = true
            store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
                toastMessage(toast, 'success', response.data.message)
                router.push({name: 'cms-category-list'})
            }).catch(error => {
                toastMessage(toast, 'danger', error.response.data.error_message)
            }).finally(message => {
                busy.value = false
            })
        }
        const changeTitleValue = (languageId) => {
            dataInfo.value.languages.filter(function (language) {
                if (language.language_id === languageId) {
                    language.h2_title = language.title
                    language.h3_title = language.title
                    language.meta_title = language.title
                }
            })
        }
        const changeDescriptionValue = (languageId) => {
            dataInfo.value.languages.filter(function (language) {
                if (language.language_id === languageId) {
                    language.meta_description = language.description
                }
            })
        }

        const {refFormObserver, getValidationState, resetForm} = formValidation()

        busy.value = true
        store.dispatch('store-language/fetchItems').then(languages => {

            let getData = null;
            if (router.currentRoute.params.id > 0) {
                busy.value = true
                store.dispatch('store/fetchItem', {id: router.currentRoute.params.id > 0 ? router.currentRoute.params.id : 0}).then(response => {
                    getData = response.data.data
                    dataInfo.value.id = getData.id;
                    dataInfo.value.main_category_id = getData.main_category_id;
                    dataInfo.value.status = getData.status;

                    mainCategoryOptions.value = mainCategoryOptions.value.filter(e => e.value !== getData.id)

                    languages.data.data.forEach((value, index) => {
                        let gryLanguage = getData.language_list.filter(function (language) {
                            if (language.language_id === value.id) return language;
                            return null;
                        });

                        if (value.panel_status === 1) {
                            dataInfo.value.languages.push({
                                language_id: value.id,
                                language_name: value.name,
                                title: gryLanguage[0].title,
                                description: gryLanguage[0].description,
                                image_url: gryLanguage[0].image_url,
                                imageBase64: null,
                                mobile_image_url: gryLanguage[0].mobile_image_url,
                                mobileImageBase64: null,
                                h2_title: gryLanguage[0].h2_title,
                                h3_title: gryLanguage[0].h3_title,
                                meta_title: gryLanguage[0].meta_title,
                                meta_description: gryLanguage[0].meta_description,
                                meta_keywords: gryLanguage[0].meta_keywords,
                            })
                        }
                    })

                }).catch(error => {
                    toastMessage(toast, 'danger', error.response.data.error_message)
                    if (error.response.status === 403) {
                        router.push({name: 'cms-category-list'})
                    }
                }).finally(message => {
                    busy.value = false
                })
            } else {
                languages.data.data.forEach((value, index) => {
                    if (value.panel_status === 1) {
                        dataInfo.value.languages.push({
                            language_id: value.id,
                            language_name: value.name,
                            title: '',
                            description: '',
                            image_url: null,
                            imageBase64: null,
                            mobile_image_url: null,
                            mobileImageBase64: null,
                            h2_title: '',
                            h3_title: '',
                            meta_title: '',
                            meta_description: '',
                            meta_keywords: '',
                        })
                    }
                })
            }
        }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
            busy.value = false
        })


        const mainCategoryOptions = ref([])
        busy.value = true
        store.dispatch('store/fetchItems').then(response => {
            response.data.data.forEach((value, index) => {
                if (value.status === 1 && value.id !== dataInfo.value.id) {
                    mainCategoryOptions.value.push({label: value.title, value: value.id})
                }
            })
        }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
            if (error.response.status === 403) {
                router.push({name: 'cms-category-list'})
            }
        }).finally(message => {
            busy.value = false
        })

        return {
            busy,
            dataInfo,
            refFormObserver,

            mainCategoryOptions,

            statusOptions,

            onSubmit,
            getValidationState,
            resetForm,
            getApiFile,
            avatarText,
            inputImageRenderer,
            selectFile,
            resetFile,
            changeTitleValue,
            changeDescriptionValue,
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
